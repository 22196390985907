/* html, body {
  font-size: 14px;
} */
[class*="sidebar-dark-"]{
  background-color: #4b545c;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #e7e7e7;
}

.sidebar-collapse .hide-when-collapse {
  display: none;
}
[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  color: #e7e7e7;
}

.brand-link .brand-image {
    float: none;
}

.table>:not(caption)>*>* {
  font-size: 14px;
  padding: 0.75rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-size: 14px;
  font-weight: 500;
}

.react-responsive-modal-modal {
  margin: 70px 1.2rem 1.2rem 1.2rem;
}

.main-header  {
  z-index: 995;
}

.page-item.active .page-link {
  background-color:#17a2b8;
  border-color: #17a2b8;
}